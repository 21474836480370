@font-face {
  font-family: "Branding Semibold";
  src: local("Branding Semibold"),
  url("./resources/fonts/branding-2-cufonfonts/Branding Semibold.otf") format("truetype");
}

@font-face {
  font-family: "Branding Bold";
  src: local("Branding Bold"), url("./resources/fonts/branding-2-cufonfonts/Branding Bold.otf") format("truetype");
}

@font-face {
  font-family: "Branding Medium";
  src: local("Branding Medium"), url("./resources/fonts/branding-2-cufonfonts/Branding Medium.otf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url("./resources/fonts/openSans/opensans-regular.ttf") format("truetype");
}

@media (min-width: 1025px) {
  body {
    background-image: url("./resources/images/svg/BackgroundSiteGroup.svg");
    background-size: cover;
    background-attachment: fixed;
  }
}

#root {
  width: 100%;
  height: 100%;
}

@media (min-width: 481px) and (max-width: 1024px) {
  #root {
    background-image: none;
    background: linear-gradient(90deg, #fefafa 0%, #fdebee 10%, #fefafa 56%, #fefaf3 93%, #fefafa 100%);
  }
}

@media (max-width: 480px) {
  body {
    background-image: none;
    background: linear-gradient(90deg, #fefafa 0%, #fdebee 10%, #fefafa 56%, #fefaf3 93%, #fefafa 100%);

  }
}

@font-face {
  font-family: "Yesevaone";
  src: local("Yesevaone"), url("./resources/fonts/yesevaOne/yesevaone-regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

@keyframes wave {
  0% {
    box-shadow: 0px 0px 10px #ef7d8f;
  }
  25% {
    box-shadow: 0px 0px 15px #ef7d8f;
  }
  50% {
    box-shadow: 0px 0px 20px #ef7d8f;
  }
  75% {
    box-shadow: 0px 0px 15px #ef7d8f;
  }
  100% {
    box-shadow: 0px 0px 10px #ef7d8f;
  }
}

